/* Container for the entire broadcast */
.tabContent-box-broadcast {
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.tabContent-box-broadcast header {
  text-align: left;
}

/* Each content container */
.tabContent-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* For the h1 element */
.tabContent-container h1 {
  font-size: 20px;
  text-align: left;
}

/* For the icons and subtitle */
/* .tabContent-container div {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--blue);
} */
.MuiPaper-root {
  width: 100%;
  box-shadow: none !important;
}
/* Stepper and its elements */
.MuiStepper-root {
  background-color: transparent;
  margin: 15px;
}

/* Text fields */
.MuiTextField-root {
  margin: 15px !important;
}

/* DataGrid style */
.MuiDataGrid-root {
  height: 319px !important;
  width: 100%;
  margin: 16px 0;
}

/* Buttons */
.MuiButton-root {
  margin: 8px;
}
.file-input {
  margin: 20px;
}
.broadcast-buttons {
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.broadcast-buttons .broadcast-button {
  min-width: 200px;
  padding: 8px 20px;
  border-radius: 200px;
}

.broadcast-buttons .broadcast-button-back {
  background: var(--light);
}
.broadcast-buttons .broadcast-button-next {
  background: var(--blue);
  color: #fff;
}
