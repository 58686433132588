.chatbox-tap-wrapper {
  display: flex;
  flex-direction: row;

  height: 100vh;
}
.chatbox-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  background-color: white;
  border: solid #dfdff3 1px;
  border-radius: 4px;
  position: relative;
  z-index: 2;
}

.chatbox-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid #dfdff3 1px;
  height: 10px;
  flex-grow: 1;
}
.chatbox-header h1 {
  width: 90%;
  margin-top: 0px;
}
.text-container {
  height: 200px;
  border: solid #dfdff3 1px;
  background-color: #f4f4f8;
  border-radius: 4px;
  margin: 1%;
}

.text-box {
  width: 85%;
  max-width: 90%;
  max-height: 50%;
  background-color: #f4f4f8;
  padding: 18px;
  /* padding-left: 16px;
    padding-right: 16px; */
  border: none;
  outline: none;
  font-size: 14px;
}

.messages-container {
  background: #f8f8f8;
  padding: 3% 2% 0% 3%;
  width: 92.6%;
  margin: 9px auto 2px auto;
  border-radius: 3px;
  border: 1px solid #ebebeb;
  color: #898d9f;
  text-align: center;
  overflow-y: scroll;
}

.send_text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.send-button {
  background-color: var(--blue);
  /* Green */
  border: none;
  color: white;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  border-radius: 6px;
  cursor: pointer;
}

.send-button:active {
  background-color: var(--blue);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.chat-widget-container {
  width: 100%;
  /* bottom: initial;
  top: initial;
  height: 450px;
  width: 38.7vw; */
}

.rcw-widget-container {
  /* bottom: initial; */
  /* right: initial;
  width: 38.7vw; */
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
}

.rcw-conversation-container {
  height: initial;
  border-radius: 0px;
  box-shadow: none;
  min-width: 200px;
  max-width: 90vw;
  position: relative;
}
.rcw-header {
  padding: 0 !important;
  height: 10px !important;
}
.rcw-conversation-container .rcw-header {
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  color: #000;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 15px 0 25px;
}

.rcw-conversation-container .rcw-title {
  font-size: 14px;
  margin: 0;
  padding: 15px 0;
}

.rcw-messages-container {
  background-color: #fff;
  height: calc(100vh - 200px) !important;
  max-height: calc(100vh - 200px) !important;
  overflow-y: scroll;
  padding-top: 10px;
  -webkit-overflow-scrolling: touch;
}
.rcw-new-message {
  flex-grow: 1;
  margin: 0;
  height: 50px;
}
.rcw-send {
  margin-top: 3px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--blue);
  background: var(--blue-lighter);
  display: flex;
  justify-content: center;
  align-items: center;
}
.rcw-send img {
  width: 20px;
  height: 20px;
  margin: 0;
  transform: translate(3px, 2px);
}
.rcw-send:hover {
  background: var(--blue);
}
.rcw-picker-btn {
  display: none;
  background: #f4f7f9;
  border: 0;
  cursor: pointer;
}
.rcw-launcher {
  display: none;
}
.rcw-timestamp {
  display: none;
}
.rcw-sender {
  flex-direction: row;
  gap: 8px;
  max-height: max-content;
  flex-wrap: wrap;
}
#messages::-webkit-scrollbar {
  width: 10px;
}

#messages::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 20px;
}

#messages::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}
.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.custom-checkbox {
  margin-right: 5px;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #007bff;
  appearance: none;
  outline: none;
}

.custom-checkbox:checked {
  background-color: #007bff;
  border: none;
}
.custom-label {
  margin-right: 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root:hover {
  background: var(--blue-lighter);
}
.close-dialog-btn {
  width: 32px;
  height: 32px;
  background: var(--blue-lighter);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
}
.close-dialog-btn:hover {
  background: var(--blue);
  color: #fff;
}

.quick-button {
  background: var(--blue-lighter) !important;
  border: none !important;
  color: var(--blue) !important;
  transition: all o.2s ease;
}
.quick-button:hover {
  background: var(--blue) !important;
  color: #fff !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  background: var(--blue-lighter) !important;
  border-radius: 4px !important;
  min-height: 30px !important;
}

.css-smkl36-MuiCollapse-wrapper {
  border-top: 1px solid var(--blue-light) !important;
  background: var(--blue-lighter) !important;
}

.rcw-message-text {
  background: var(--blue-lighter) !important;
}
