.chats-header {
  margin-bottom: 16px;
  padding: 0 12px;
}
.chats-header .chats-header-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--blue);
  margin-bottom: 8px;
}
.chats-header .chats-search-input input {
  width: 100%;
  height: 28px;
  border-radius: 4px;
  border: 1px solid var(--blue-light);
  color: (--blue);
  background: #ffffff;
}
.chats-header .chat-filter-check label {
  display: flex;
  gap: 8px;
  font-size: 11px;
}

.tabContent-box-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  padding-top: 20px;
  min-width: 210px;
  width: 210px;
  overflow: hidden;
  border: solid #dfdff3 1px;
  border-radius: 4px;
}

.tabContent-container {
  border-bottom: solid #dfdff3 1px;
  width: 100%;
}

.message-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #002412;
}

.avatar-icon {
  color: white;
  width: inherit;
}

.user-message {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  border-radius: 8px;
  margin-bottom: 4px;
  overflow: hidden;
}

.user-message h5 {
  color: #094da5 !important;
  font-size: 12px;
}

.user-message h6 {
  color: #827dbd !important;
  font-style: italic;
  font-size: 12px;
}

.user-message:hover {
  background-color: var(--blue-lighter);
}

.user-message-active {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  background-color: var(--blue-light);
  border-radius: 8px;
  margin-bottom: 4px;
  overflow: hidden;
}

.search-bar {
  width: 100%;
  height: 35px;
  padding: 5px 10px;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f0f0f0;
  transition: 0.3s;
  margin-bottom: 15px;
  outline: none;
}

.search-bar:focus {
  box-shadow: 0 0 10px rgba(33, 33, 33, 0.2);
  background-color: #ffffff;
}

.badge {
  position: relative;
  top: 10px;
  left: 25px;
  padding: 4px;
  border-radius: 50%;
  background-color: #827dbd;
  color: white;
  font-size: 10px;
  font-weight: bolder;
  min-width: 15px;
  text-align: center;
}

.patients-list::-webkit-scrollbar {
  width: 10px;
}

.patients-list::-webkit-scrollbar-thumb {
  background: var(--blue);
  border-radius: 20px;
}

.patients-list::-webkit-scrollbar-track {
  background: var(--blue-lighter);
  border-radius: 20px;
}
.patients-list {
  padding: 12px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container label {
  margin-right: 10px;
}
