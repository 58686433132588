.bookmarks-container {
  padding: 20px 0 20px 32px;
  width: 350px;
  max-width: 100%;
}
.bookmarks-container header {
  margin-bottom: 20px;
}
.bookmarks-container header h1 {
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: 600;
}

.bookmark-cards {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.bookmark-card {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  border-radius: 200px;
  background-color: var(--blue-lighter);
  text-decoration: none;
  color: var(--blue);
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.bookmark-card:hover {
  background-color: var(--blue);
  color: #fff;
}
