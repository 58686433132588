.sidebar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
  padding: 20px 12px;
  /* position: fixed;; */
  overflow-y: auto;
  border-right: 1px solid var(--blue-lighter);
}

.layout-wrapper {
  display: flex;
  height: 100vh;
}

.tabContent {
  width: calc(100% - 100px);
  overflow-y: auto;
}
.tab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  margin-top: 20px;
  width: 100%;
}

.tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.3em;
  padding-top: 3em;
  background: var(--blue-light);
  padding: 1.8em 0 1.8em 0.3em;
  border-radius: 0.8em;
  width: 100%;
}

.tab-item {
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px 0;
  border-radius: 200px 0 0 200px;
  color: var(--blue);
  cursor: pointer;
}
.tab-item:hover {
  background: #fff;
  color: var(--blue);
}
.tab-item-active {
  background: #fff;
  color: var(--blue);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
}
.tab-item-active:hover {
  background: #fff;
  color: var(--blue);
}
.tab-item-active::after {
  position: absolute;
  content: "";
  right: -4px;
  top: 0;
  bottom: 0;
  background: #fff;
  width: 4px;
}

.icon-el {
  color: currentColor;
  /* width: 28px;
	  height: 28px;
	  cursor: pointer; */
}

.icon-el-active {
  width: 28px;
  height: 28px;
  color: var(--blue);
  cursor: pointer;
}

.avatar {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-color: #4b4c91;
  margin-bottom: 50px;
}

div.sidebar-element {
  position: fixed;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--blue);
}

.sidebar-base {
  display: flex;
  background: #fff;
  padding-bottom: 0;
  backdrop-filter: blur(3px);
}
.version-info {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 12px;
  color: #ccc;
}

.logoutBtn {
  height: 36px;
  width: 36px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: none;
  color: var(--blue);
  border: 2px solid var(--blue);
  font-size: 0.8em;
  /* width: 100%; */
  margin-right: 4px;
}

.logoutBtn:hover {
  background: var(--blue);
  color: #fff;
}

.logoutBtn svg {
  font-size: 1.75em;
}

.sidebar-logo-container img {
  width: 50px;
}
