@import "form.css";
.btn {
  border: none;
  cursor: pointer;
  padding: 8px 20px;
  border-radius: 100px;
  font-size: 15px;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-width: 120px;
}

.btn-primary {
  background-color: var(--blue);
  color: white;
}

.btn-danger {
  background-color: var(--red);
  color: white;
}
.btn-block {
  width: 100%;
}

button:disabled,
button:disabled:hover {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}
