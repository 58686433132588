.settings-container {
  color: var(--blue);
  padding: 20px;
  min-height: 100vh;
  overflow-y: auto;
}

.setting-section {
  font-size: 20px;
  text-align: left;
}

.icon-subtitle {
  display: flex;
  gap: 8px;
  align-items: center;
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--blue);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.textarea-container {
  margin-top: 20px;
}

.autoresponse-textarea {
  width: 100%;
  padding: 10px;
  border: 2px solid var(--blue);
  border-radius: 5px;
}
.save-confirmation {
  color: green;
  margin-right: 10px;
}
.disabled {
  background-color: #e0e0e0;
}
.save-button {
  background-color: var(--blue);
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.save-button:hover {
  background-color: var(--blue);
  color: white;
}

.auto-signature-textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
}

.css-1ujykiq-MuiButtonBase-root-MuiTab-root:hover {
  background: var(--blue-lighter);
}

.days-checkboxes {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}

.days-checkboxes label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.time-range-container tr td,
.time-range-container tr th {
  padding: 5px 20px;
  text-align: left;
}
