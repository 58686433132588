.field-control {
  margin-bottom: 16px;
}
.field-control label {
  display: block;
  margin-bottom: 8px;
  font-size: 13px;
}
.field-control input[type="text"],
.field-control input[type="password"],
.field-control input[type="email"],
select {
  height: 36px;
  border: 1px solid #ddd;
  font-size: 16px;
  border-radius: 100px;
  display: block;
  padding: 2px 15px;
  width: 100%;
}
.field-control textarea {
  min-height: 120px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  display: block;
  padding: 15px;
  width: 100%;
}
.field-control .field-error {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  display: block;
}

.field-control select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

.field-control input[type="text"]::placeholder,
.field-control input[type="password"]::placeholder,
.field-control input[type="email"]::placeholder {
  color: #bbb;
}
