.tabContent-box-newcontact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 220px;
  height: 100%;
  background-color: #f0f0f3;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.tabContent-container {
  border-bottom: solid #dfdff3 1px;
  width: 100%;
}
