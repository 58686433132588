/* TagsInput.css */
.tags-input-container {
    display: flex;
    flex-direction: column;
  }
  
  .tags-input-row {
    display: flex;
    align-items: center;
  }
  
  .tags-input-field {
    flex-grow: 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
  }
  
  .tags-input-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 5px 20px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;

}
  
  .tags-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
  
  /* Style the tags as small chips */
  .MuiChip-root {
    font-size: 12px;
    margin: 0;
    border-radius: 12px;
    background-color: #f0f0f0;
    color: #333;
  }
  
  /* Style the delete icon in tags */
  .MuiChip-deleteIcon.MuiIconButton-root {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    margin-right: -4px;
  }
  
  /* Style the delete icon when hovering */
  .MuiChip-deleteIcon.MuiIconButton-root:hover {
    background-color: #ccc;
  }

    /* Style the custom smallChip class */
    .smallChip {
        font-size: 10px !important; /* Adjust the font size to make them smaller */
        margin: 4px !important; /* Adjust spacing as needed */
        border-radius: 12px !important; /* Adjust the border radius to your preference */
        background-color: #f0f0f0 !important; /* Background color */
        color: #333 !important; /* Text color */
    }
    .smallChip label {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }