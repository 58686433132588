@import "./styles/global.css";
@import "./styles/utilities.css";
@import "./styles/components.css";

.my-element {
  color: var(--my-variable);
}

.logo {
  display: block;
  margin: 0 auto 20px;
  width: 200px;
}
.signUpSection {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 100%;
}

.tabContent-box-newcontact,
.org-container {
  width: 400px;
  max-width: 100%;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: #fff;
}

.tabContent-box-newcontact form,
.org-container form {
  width: 100%;
}

.tabContent-container {
  width: 100%;
  display: flex;
  align-items: center;
}

/* form {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

div {
  /* margin-bottom: 15px; */
}

.inputDiv {
  display: flex !important;
  gap: 8px !important;
  align-items: flex-start !important;
  color: var(--blue) !important;
  width: 70% !important;
  flex-direction: column;
}

.inputDiv label {
  display: block;
  margin-bottom: 5px;
}

.inputDiv input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 6px;
}

button {
  cursor: pointer;
  background: var(--blue);
  border-radius: 1000px;

  /* background-color: var(--blue);
  color: white;
  border: none;
  cursor: pointer;
  padding: 8px 50px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 600;
  margin: 20px 0;
  width: 40%; */
}
.signup-btn {
  background-color: var(--blue);
  color: white;
  border: none;
  cursor: pointer;
  padding: 8px 50px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 600;
  margin: 20px 0;
  width: auto;
}
.signup-btn:hover {
  background-color: var(--blue);
}

.textHeader {
  font-size: 15px;
  margin-bottom: 23px;
}
.errorText {
  color: red;
}
