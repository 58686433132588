.email-container {
  padding: 20px 0 20px 32px;
  width: 500px;
  max-width: 100%;
}
.email-container header {
  margin-bottom: 20px;
}
.email-container header h1 {
  margin-bottom: 4px;
}
.create-email-form {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 12px;
}
.create-email-form button {
  background-color: var(--blue);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
}
.email-list-item {
  display: flex;
  align-items: center;
  height: 36px;
  gap: 12px;
  background: var(--blue-lighter);
  border-radius: 100px;
  padding: 2px 4px 2px 16px;
}
.email-list-item-text {
  flex: 1;
  font-weight: 700;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 100px;
}
.email-list-item-btn {
  background-color: var(--blue);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  border: none;
}
