* {
  box-sizing: border-box;
  margin: 0;
}

html {
  min-width: 800px;
  min-height: 600px;
}

body {
  background-color: #fff;
  overflow: hidden !important;
  font-family: "Arial";
  margin: 0;
  padding: 0;
}

:root {
  --blue: #1e346f;
  --blue-light: #b5c8ef;
  --blue-lighter: #edf3ff;
  --red: #e91e1e;
}

.text-negative {
  color: red;
}

h1 {
  font-size: 24px;
  font-weight: bold;
}
