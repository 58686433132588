.user-details-container {
  padding: 20px 12px;
  width: 200px;
  min-width: 200px;
  color: #000;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .user-details-container {
    display: none;
  }
}

.user-details-container .patient-info-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}
.patient-info-header .action-btn {
  height: 28px;
  width: 28px;
  background: var(--blue-light);
  color: var(--blue);
  font-size: 12px;
  padding: 2px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  transition: all 0.2 ease;
}
.patient-info-header .action-btn:hover {
  background: var(--blue);
  color: #fff;
}

.patient-basic-info {
  margin-bottom: 16px;
}

.patient-basic-info p {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.patient-info-actions {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--blue-light);
}

.patient-info-action-button {
  border: none;
  background: var(--blue-light);
  padding: 6px;
  width: 100%;
  color: var(--blue);
  border-radius: 100px;
  display: flex;
  gap: 8px;
  transition: all 0.2 ease;
}
.patient-info-action-button + .patient-info-action-button {
  margin-top: 8px;
}

.patient-info-action-button:hover {
  background: var(--blue);
  color: #fff;
}

.notes-input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--blue-light);
  height: 80px;
}

.save-notes-btn {
  margin-top: 8px;
  font-weight: bold;
  background: var(--blue-light);
  color: var(--blue);
  border: none;
  padding: 4px;
  width: 100%;
  border-radius: 100px;
  transition: all 0.2 ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.save-notes-btn:hover {
  background: var(--blue);
  color: #fff;
}
