.users-page-container {
  padding: 20px;
  max-width: 100%;
}

.users-table .MuiTableHead-root {
  background: var(--blue-light);
}

.users-table .MuiTableCell-head {
  font-size: 16px;
  font-weight: 600;
  color: var(--blue);
  padding: 12px;
}
.delete-user-btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--blue);
  font-size: 10px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.add-user-form {
  width: 400px;
  max-width: 100%;
}
